import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'button', 'input', 'form', 'factContainer', 'fact' ]
  static values = { id: Number }

  connect() {
    // console.log("Hello, Game Recap Controller here!", this.element)
    // console.log(this.inputTarget)
  }

  uploadFile() {
    this.inputTarget.click();
  }

  updateFileName() {
    this.buttonTarget.innerText = this.inputTarget.files[0].name;
  }

  addFact(event) {
    event.preventDefault();

    fetch(`/game_recaps_games/${this.idValue}/fact`).then(res => res.text()).then(partial => {
      partial = partial.replace(/NEW_ID/g, this.factTargets.length + 1)

      this.factContainerTarget.insertAdjacentHTML( 'beforeend', partial );

      // add focus to newly created input
      this.factTargets[this.factTargets.length - 1].querySelector('input').focus();
    });
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "editTextArea", "approval", "approvalForm" ]

  connect() {
    // console.log("Hello, Athlete Degrees Controller here!", this.element)
    // console.log(this.containerTarget);
    this.approvalTargets.forEach(elm => elm && elm.addEventListener('change', this.submitApprovalForm.bind(this)))

    this.increaseTextAreaSize();
  }

  increaseTextAreaSize() {
    this.editTextAreaTarget.style.height = (40 + this.editTextAreaTarget.scrollHeight) + "px";
  }

  submitApprovalForm(event) {
    this.approvalFormTarget.submit();
  }

}

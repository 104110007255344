/* Usage:
 *
 * Container element must have the following data attributes:
 * - data-crop-width-value: the width of the cropped image
 * - data-crop-height-value: the height of the cropped image
 * - data-crop-aspect-ratio-value: the aspect ratio of the cropped image
 * - data-crop-file-name-value: the name of the file to be uploaded
 * - data-action='crop:cropped->page-controller#previewImage' to optionally update a preview of the cropped image

 * HTML File Input field should have the following data attributes:
 * - data-crop-target="input" to identify the input field in the controller
 * - data-action='change->crop#changeHandler' to trigger the crop modal flow
 *
 * Once cropping is complete, an event will be emitted from this controller and can be listened to like this:
 *
 * previewImage(event) {
    if (event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {

        // set the preview as the url
        this.previewTarget.src = e.target.result;
      };

      reader.readAsDataURL(event.detail.content.files[0]);
    }
  }
 *
 * Stimulus Cropper Modal also needs to be included in the HTML file
 * - <%= render partial: 'layouts/cropper_stimulus' %>
 */

import { Controller } from "@hotwired/stimulus"
import * as Cropper from 'cropperjs'
import createAlert from '../packs/createAlert';

let cropModal, // HTML crop modal element
    cropperInstance; // instance of the Cropper library

export default class extends Controller {
  static targets = [ "input", "modal" ]
  static values = {
    fileName: String,
    width: Number,
    height: Number,
    aspectRatio: Number
  }

  connect() {
    // console.log("Hello, Crop Controller here!", this.element)
    // console.log(this.previewTarget)
    // console.log(this.modalTarget)
    // console.log(this.modalHeaderTarget)

    const template = document.getElementsByClassName('stimulus-crop-modal-template')[0];
    this.element.appendChild(template.cloneNode(true));
  }

  modalTargetConnected(target) {
    cropModal = new bootstrap.Modal(target);

    target && target.addEventListener('hidden.bs.modal', (e) => { console.log('modal is hidden'); this.destroyCropper(e) });

    // cropper must be initialized after the modal is **shown**
    target && target.addEventListener('shown.bs.modal', (e) => { console.log('modal is shown'); this.initializeCropper(e) });
  }

  changeHandler(event) {
    // console.log('Change handler is running!');

    // get the target dimensions for the image that was just selected
    // console.log('Cropped upload requested', event.target.dataset)
    // cropWidth = event.target.dataset.width
    // cropHeight = event.target.dataset.height
    // aspectRatio = event.target.dataset.aspectratio

    // previewId = event.target.dataset.previewId;

    // check filesize
    const fileSize = event.target.files[0].size;
    const fileMb = fileSize / 1024 ** 2;

    if (fileMb > 5) {
      createAlert('warning', 'Original file size cannot be bigger than 5mb. Please upload a smaller file.')
      // cropModal.hide();

      // zero out the uploaded files on the fileInput
      let dataTransfer = new DataTransfer()
      this.inputTarget.files = dataTransfer.files

      return false;
    }

    const that = this; // create self with right this

    const reader = new FileReader();
    const loaded = (url) => {
      // display the image in the crop modal
      const cropContainer = that.modalTarget.querySelector('.img-container img');
      cropContainer.setAttribute('src', url);

      // open the crop modal
      cropModal = new bootstrap.Modal(that.modalTarget);
      cropModal.show();
    }

    reader.onloadend = (event) => {
      loaded(reader.result);
    };

    reader.readAsDataURL(event.target.files[0]);
    // fileName = event.target.files[0].name
  }

  initializeCropper(event) {
    const cropContainer = this.modalTarget.querySelector('.img-container img');
    cropperInstance = new Cropper(cropContainer, {
      dragMode: 'move',
      aspectRatio: this.aspectRatioValue,
      viewMode: 1,
      cropBoxResizable: false,
      minCropBoxWidth: this.widthValue,
      minCropBoxHeight: this.heightValue,
    });
  }

  destroyCropper(event) {
    cropperInstance.destroy();
  }

  async doCrop(event) {
    cropModal.hide();

    const canvas = cropperInstance.getCroppedCanvas({
      width: this.widthValue,
      height: this.heightValue,
    });

    // update the form data
    const dataTransfer = new DataTransfer()
    const blob = await new Promise(resolve => canvas.toBlob(resolve));
    const file = new File([blob], `${this.fileNameValue}-cropped.jpg`, { type: 'image/jpeg' })
    dataTransfer.items.add(file)
    //document.querySelector('#cropped-upload').files = dataTransfer.files
    this.inputTarget.files = dataTransfer.files

    // anything that is listening for the cropping to be done can be notified by this event
    this.dispatch('cropped', { detail: { content: this.inputTarget } } );
  }

  zoom(event) {
    cropperInstance.zoom(event.params.zoom);
  }

  move(event) {
    cropperInstance.move(parseInt(event.params.movex), parseInt(event.params.movey));
  }

  cropCancel(event) {
    cropModal.hide();

    fileName = undefined;
    const dataTransfer = new DataTransfer()
    this.inputTarget.files = dataTransfer.files
  }

}

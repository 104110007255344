import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "helpSection" ]

  connect() {
    // console.log("Hello, Stories Help Controller here!", this.element)
    // console.log(this.helpSectionTargets)

    // Listen for tabs to change and retrigger tour logic
    var tabs = document.querySelectorAll('button[data-bs-toggle="tab"]')
    tabs.forEach(elm => {
      elm.addEventListener('shown.bs.tab', (event) => {
        const target = event.target.dataset.helpTarget || event.target.dataset.bsTarget;
        this.displayHelpSection(target); // #subject or #responses
        this.updateHelpBody(event.target.dataset.tourKey);
      });
    });
  }

  openHelp() {
    // console.log('Opening help!');
    const help = document.querySelector("#stories-help-offcanvas");
    help && help.classList.add('open');
  }

  closeHelp() {
    // console.log('Closing help!');
    const help = document.querySelector("#stories-help-offcanvas");
    help && help.classList.remove('open');
  }

  displayHelpSection(id) {
    this.helpSectionTargets.forEach(elm => {
      elm.classList.remove('d-block');
      elm.classList.add('d-none');
    });

    const helpBody = document.querySelector(`${id}-help-body`);
    helpBody && helpBody.classList.remove('d-none');
    helpBody && helpBody.classList.add('d-block');
  }

  updateHelpBody(tourKey) {
    document.querySelector('.show-onboarding-tip-button').dataset.tourKey = tourKey
  }
}

const createAlert = (type, text) => {
  const template = `<div class="alert alert-${type} alert-dismissible fade show" role="alert">
    ${text}
  </div>`;

  $('body').append(template);
  
  $('.alert').delay(3000).fadeOut(1000)
}

module.exports = createAlert
// src/controllers/interviewer_controller.js
import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';
import AudioRecorder from 'audio-recorder-polyfill'

const MAX_INTERVIEW_LENGTH = 30;
let mediaRecorder, audioBlob, audioElement, chunks = [], combinedBlobs;

export default class extends Controller {
  static targets = [
    "text", "recordButton", "pauseButton", "resumeButton",
    "audioContainer", "audioFile", "submitButton",
    "radio", "contentContainer", "lengthIndicator",
    "errorContainer", "startVideoRecording", "stopVideoRecording", 
    "videoElement", "videoFile", "tabPane",
    "questionAudio", "questionPlay", "questionPause" ]
  static values = {
    interval: Number,
    duration: String,
    bugReportPath: String
  }

  connect() {
    self.error = '';

    this.initAudio();

    // Enable the use of radio buttons to control tabs
    this.radioTargets.forEach((radio) => {
      radio.addEventListener('click', (event) => {
        this.toggleInputDisplay(event);
      });
    });

  }

  toggleInputDisplay(event) {
    this.tabPaneTargets.forEach((tab) => {
      tab.classList.remove('show', 'active');
    });

    let tabId = event.target.id.replace("-radio", "");
    document.getElementById(tabId).classList.add('show', 'active');
  }

  initAudio() {
    //check if browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording!');
      return;
    }
  }

  async startRecordVideo() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then( (stream) => {

        if ("srcObject" in this.videoElementTarget) {
          this.videoElementTarget.srcObject = stream;
        } else {
          // Avoid using this in new browsers, as it is going away.
          this.videoElementTarget.src = URL.createObjectURL(stream);
        }

        mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"', videoBitsPerSecond: 2500000, audioBitsPerSecond: 128000 });

        this.startVideoRecordingTarget.classList.remove('d-block');
        this.startVideoRecordingTarget.classList.add('d-none');

        this.stopVideoRecordingTarget.classList.remove('d-none');
        this.stopVideoRecordingTarget.classList.add('d-block');

        this.videoElementTarget.classList.remove('d-none');

        mediaRecorder.start();

        mediaRecorder.addEventListener('dataavailable', e => {
          // console.log('dataavailable for video chunks');

          const videoURL = window.URL.createObjectURL(e.data);
          this.videoElementTarget.srcObject = null;
          this.videoElementTarget.src = videoURL;

          this.stopVideoRecordingTarget.classList.remove('d-block');
          this.stopVideoRecordingTarget.classList.add('d-none');

          this.startVideoRecordingTarget.classList.remove('d-none');
          this.startVideoRecordingTarget.classList.add('d-block');

          this.videoElementTarget.classList.remove('d-none');
          this.videoElementTarget.classList.add('d-block');

          let file = new File([e.data], "video-message.wav", {
            type: 'video/mp4; codecs="avc1.424028, mp4a.40.2',
            lastModified: new Date().getTime(),
          });
          let container = new DataTransfer();
          container.items.add(file);
          // uploadFile(file);
          this.videoFileTarget.files = container.files;
          this.submitButtonTargets.forEach(elm => elm.disabled = false);

          this.videoElementTarget.play();
        });

    }).catch( (error) => {
        alert('Unable to capture your camera. Please check console logs.');
        console.error(error);
    });
  }

  stopRecordVideo() {
    mediaRecorder.stop();
  }

  async record(event) {
    if (event.target.dataset.confirmSwal) {
      // need to confirm first
      return;
    }

    window.MediaRecorder = AudioRecorder

    await navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
      // console.log('Starting record');

      mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.addEventListener('dataavailable', e => {
        this.mediaRecorderDataAvailable(e.data);
      })

      mediaRecorder.addEventListener('pause', e => {
        mediaRecorder.requestData();
      })

      // zero out the chunks, so we have a fresh recording
      chunks = [];

      // hide the record button
      this.recordButtonTarget.classList.remove('d-block')
      this.recordButtonTarget.classList.add('d-none')

      // hide the resume button
      this.resumeButtonTarget.classList.remove('d-block')
      this.resumeButtonTarget.classList.add('d-none')

      // show the pause button
      this.pauseButtonTarget.classList.remove('d-none')
      this.pauseButtonTarget.classList.add('d-block')

      // show the progress bar
      if (this.hasLengthIndicatorTarget) {
        this.lengthIndicatorTarget.classList.remove('d-none');
        this.lengthIndicatorTarget.classList.add('d-block');
      }

      if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
        this.audioContainerTarget.firstElementChild.remove();
      }

      // disable submit button
      this.submitButtonTargets.forEach(elm => elm.disabled = true);

      // start recording
      mediaRecorder.start();

      if (this.hasLengthIndicatorTarget) {
        this.startInternalRecordingTimer(0);
      }

    }).catch((err) => {
      // alert('An error occured when starting audio recorder. Please try a new web browser or contact FanWord for help.')
      console.error(`The following error occurred: ${err}`);

      this.error = err;

      this.contentContainerTarget.classList.remove('d-block');
      this.contentContainerTarget.classList.add('d-none');

      this.errorContainerTarget.classList.remove('d-none');
      this.errorContainerTarget.classList.add('d-block');
    });

  }

  resume() {
    //check if there are any previous recordings and remove them
    if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
      this.audioContainerTarget.firstElementChild.remove();
    }

    // hide the resume button
    this.resumeButtonTarget.classList.remove(('d-block'))
    this.resumeButtonTarget.classList.add(('d-none'))

    // hide the resume button
    this.recordButtonTarget.classList.remove(('d-block'))
    this.recordButtonTarget.classList.add(('d-none'))

    // show the pause button
    this.pauseButtonTarget.classList.remove(('d-none'))
    this.pauseButtonTarget.classList.add(('d-block'))

    // start recording
    mediaRecorder.resume();
    this.startInternalRecordingTimer(parseInt(this.durationValue));
  }

  // This method is actually triggered from a button that is labeled "Stop"
  // because that is what users will probably understand it as. But if we
  // used the mediaRecorder.stop() method, it would set the state on the
  // recorder to "inactive" and it couldn't be restarted. So we pause it
  // instead technically, and tell the user they are stopping it.
  async pause() {
    // console.log('Pausing recording');

    //check if there are any previous recordings and remove them
    if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
      this.audioContainerTarget.firstElementChild.remove();
    }

    // pause recording
    mediaRecorder.pause();
    clearInterval(this.intervalValue);

    // hide the pause button
    this.pauseButtonTarget.classList.remove(('d-block'))
    this.pauseButtonTarget.classList.add(('d-none'))

    // show the resume recording button
    this.resumeButtonTarget.classList.remove(('d-none'))
    this.resumeButtonTarget.classList.add(('d-block'))

    // show the re-record button
    this.recordButtonTarget.classList.remove('d-none')
    this.recordButtonTarget.classList.add('d-block')
    this.recordButtonTarget.innerHTML = "<i class='fas fa-microphone me-1'></i> Record again"
    this.recordButtonTarget.dataset.confirmSwal = "Are you sure?"
    this.recordButtonTarget.dataset.text = "Re-recording will replace your previously recorded answer."
  }

  // triggered by the MediaRecorder
  async previewCapturedMedia() {
    // console.log('Preview Captured Media method');

    //create a new audio element that will hold the recorded audio
    audioElement = document.createElement('audio');
    audioElement.setAttribute('controls', ''); //add controls

    //create the unified blob from the individual chunks
    audioBlob = undefined
    let combinedBlobs = await this.concatenateBlobs(chunks, 'audio/wav');

    audioBlob = new Blob([combinedBlobs], { type: 'audio/wav' });
    const audioURL = window.URL.createObjectURL(audioBlob);
    audioElement.src = audioURL;

    //show audio
    this.audioContainerTarget.insertBefore(audioElement, this.audioContainerTarget.firstElementChild);

    let file = new File([audioBlob], "audio-message.wav", {
      type: "audio/wav",
      lastModified: new Date().getTime(),
    });
    let container = new DataTransfer();
    container.items.add(file);
    // uploadFile(file);
    this.audioFileTarget.files = container.files;
    this.submitButtonTargets.forEach(elm => elm.disabled = false);

    // reset to default
    // mediaRecorder = null;
    // chunks = []; <------ do we still need to do this??
  }

  mediaRecorderDataAvailable(e) {
    // console.log('Data available handler', e);
    chunks = [ ...chunks, e ];

    this.previewCapturedMedia()
  }

  attemptSubmit(event) {
    if(
      this.audioFileTarget.files.length === 0 &&
      this.audioContainerTarget?.firstElementChild?.tagName !== 'AUDIO' &&
      this.videoFileTarget.files.length === 0 &&
      this.textTarget.value === ''
    ) {
      event.preventDefault();
      createAlert('info', 'Please record an audio answer before moving forward.')
    }
  }

  sendBugReport() {
    const bugReport = {
      origin: window.location.href,
      error: this.error.toString(),
      cookiesEnabled: navigator.cookieEnabled,
      language: navigator.language,
      userAgent: navigator.userAgent,
      navigatorUAData: navigator.userAgentData,
      dimensions: { width: window.innerWidth, height: window.innerHeight }
    };

    fetch(this.bugReportPathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bugReport)
    });

    createAlert('info', 'Bug Report Sent!');
  }

  // shamelessly stolen from https://stackoverflow.com/a/68963536/23688378
  concatenateBlobs(blobs, type) {
    return new Promise((resolve, reject) => {
      var buffers = [];
      var index = 0;

      function readAsArrayBuffer() {
          if (!blobs[index]) {
            let blob = concatenateBuffers();
          }
          var reader = new FileReader();
          reader.onload = function(event) {
              buffers.push(event.target.result);
              index++;
              readAsArrayBuffer();
          };
          reader.readAsArrayBuffer(blobs[index]);
      }

      readAsArrayBuffer();

      function audioLengthTo32Bit(n) {
          n = Math.floor(n);
          var b1 = n & 255;
          var b2 = (n >> 8) & 255;
          var b3 = (n >> 16) & 255;
          var b4 = (n >> 24) & 255;

          return [b1, b2, b3, b4];
      }
      function concatenateBuffers() {
          var byteLength = 0;
          buffers.forEach(function(buffer) {
              byteLength += buffer.byteLength;
          });

          var tmp = new Uint8Array(byteLength);
          var lastOffset = 0;
          var newData;
          buffers.forEach(function(buffer) {
              if (type=='audio/wav' && lastOffset >  0) newData = new Uint8Array(buffer, 44);
              else newData = new Uint8Array(buffer);
              tmp.set(newData, lastOffset);
              lastOffset += newData.length;
          });
          if (type=='audio/wav') {
              tmp.set(audioLengthTo32Bit(lastOffset - 8), 4);
              tmp.set(audioLengthTo32Bit(lastOffset - 44), 40); // update audio length in the header
          }
          var blob = new Blob([tmp.buffer], {
              type: type
          });
          resolve(blob);
      }
    });
  }

  startInternalRecordingTimer(startingValue = 0) {
    // console.log('Starting internal recording timer', startingValue);

    // set existing duration, incase we are resuming
    this.durationValue = startingValue;

    // update the progress bar every second
    this.intervalValue = setInterval(this.updateLengthIndicator.bind(this), 1000)

    // run it immediately
    this.updateLengthIndicator();
  }

  updateLengthIndicator() {
    // console.log('Updating internal timer and progress bar', this.durationValue);
    this.durationValue = parseInt(this.durationValue) + 1;

    const newDuration = parseInt(this.durationValue);

    let icon, color, message;

    // determine level of progress
    if (newDuration < 15) {
      icon = 'fa-frown';
      color = 'bg-fanword-negative'
      message = 'Needs to be longer';
    } else if(newDuration >= 15 && newDuration < 30) {
      icon = 'fa-meh';
      color = 'bg-fanword-lightyellow'
      message = 'Getting there...';
    } else if(newDuration >= 30 && newDuration < 60) {
      icon = 'fa-smile';
      color = 'bg-fanword-positive'
      message = 'Good answer!';
    } else if(newDuration >= MAX_INTERVIEW_LENGTH) {
      icon = 'fa-grin-stars';
      color = 'bg-fanword-yellow'
      message = 'GREAT answer!';
      clearInterval(this.intervalValue);
    }

    // console.log('icon array', icon)

    this.lengthIndicatorTarget.classList.remove('bg-fanword-negative', 'bg-fanword-lightyellow', 'bg-fanword-positive', 'bg-fanword-yellow');
    this.lengthIndicatorTarget.classList.add(color);

    const smiley = this.lengthIndicatorTarget.querySelector('#smiley');
    smiley.classList.remove('fa-frown', 'fa-meh', 'fa-smile', 'fa-grin-stars');
    smiley.classList.add(icon);

    this.lengthIndicatorTarget.dataset['bsOriginalTitle'] = message;

    // const tooltip = new bootstrap.Tooltip(this.lengthIndicatorTarget);
    // tooltip.dispose();
    // new bootstrap.Tooltip(this.lengthIndicatorTarget);
  }

  questionPlayAudio() {
    this.questionPlayTarget.classList.remove('d-block');
    this.questionPlayTarget.classList.add('d-none');

    this.questionPauseTarget.classList.remove('d-none');
    this.questionPauseTarget.classList.add('d-block');

    this.questionAudioTarget.addEventListener('ended', () => {
      this.questionPauseTarget.classList.remove('d-block');
      this.questionPauseTarget.classList.add('d-none');

      this.questionPlayTarget.classList.remove('d-none');
      this.questionPlayTarget.classList.add('d-block');
    });

    this.questionAudioTarget.play();
  }

  questionPauseAudio() {
    this.questionPauseTarget.classList.remove('d-block');
    this.questionPauseTarget.classList.add('d-none');

    this.questionPlayTarget.classList.remove('d-none');
    this.questionPlayTarget.classList.add('d-block');

    this.questionAudioTarget.pause();
  }

}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "availability", "checkbox", "submit" ]
  static values = { requestId: Number }

  connect() {
    // console.log("Hello, Story Availability Controller here!", this.element)
    // console.log(this.containerTarget)
    // console.log(this.availabilityTargets)
    // console.log(this.requestIdValue)
    
    this.updateSubmitDisabled();
  }

  addAvailability() {
    fetch(`/requests/${this.requestIdValue}/availability`).then(res => res.text()).then(partial => {
      this.containerTarget.insertAdjacentHTML( 'beforeend', partial );

      this.updateSubmitDisabled();
    });
  }

  updateSubmitDisabled() {

    if (this.checkboxTarget.checked) {
      // console.log('checkbox is checked');
      this.submitTarget.disabled = false;
      return;
    }

    if (this.availabilityTargets.length > 0) {
      // console.log('at least one availability targets is present');
      this.submitTarget.disabled = false;
      return;
    }

    if (!this.checkboxTarget.checked && this.availabilityTargets.length === 0 && this.hasSubmitTarget) {
      // console.log('checkbox is not checked and no availabilities');
      this.submitTarget.disabled = true;
      return;
    }

  }
}

import { Controller } from "@hotwired/stimulus"

let intervalId;

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    // console.log("Hello, Stimulus from Stories Audio Transcript!", this.element)
    // console.log(this.urlValue)

    intervalId = setInterval( () => this.fetchTranscriptionJson(), 5000);
  }

  fetchTranscriptionJson() {
    try {
      fetch(this.urlValue).then(results => {
        if (results.status === 200) {
          clearInterval(intervalId);
          window.location.reload();
        }
      });
    } catch (e) {
      console.error('Error when trying to fetch transcript: ', e);
    }
  }

}


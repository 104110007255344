import { Controller } from "@hotwired/stimulus"

let playing = false;

export default class extends Controller {
  static targets = [ "audioElement", "playButton", "pauseButton", "slider", "currentTime", "totalTime" ]


  connect() {
    // console.log("Hello, Audio Player Controller here!", this.element)
    // console.log(this.audioElementTarget);

    this.audioElementTarget.addEventListener('loadedmetadata', () => {
      this.totalTimeTarget.innerText = new Date(Math.floor(this.audioElementTarget.duration) * 1000).toISOString().slice(15, 19);
    });

    this.audioElementTarget.addEventListener('timeupdate', () => {
      console.log('Time updated!', this.audioElementTarget.currentTime);
      var percentage = (Math.round(this.audioElementTarget.currentTime) / this.audioElementTarget.duration) * 100;

      this.setSliderValue(percentage);
      this.setCurrentTime(this.audioElementTarget.currentTime);
    });

    this.audioElementTarget.addEventListener('ended', () => {
      this.pause();
      this.setSliderValue(100);
    });
  }

  play() {
    this.playButtonTarget.classList.remove('d-block');
    this.playButtonTarget.classList.add('d-none');

    this.pauseButtonTarget.classList.remove('d-none');
    this.pauseButtonTarget.classList.add('d-block');

    if (this.sliderTarget.value == 100) {
      this.setSliderValue(0);
    } else {
      this.audioElementTarget.currentTime = this.audioElementTarget.duration * (this.sliderTarget.value / 100);
    }

    this.audioElementTarget.play();

    playing = true;
  }

  pause() {
    this.audioElementTarget.pause();

    this.pauseButtonTarget.classList.remove('d-block');
    this.pauseButtonTarget.classList.add('d-none');

    this.playButtonTarget.classList.remove('d-none');
    this.playButtonTarget.classList.add('d-block');

    playing = false;
  }

  updateSlider(event) {
    this.setSliderValue(event.target.value);
  }

  setSliderValue(value) {
    this.sliderTarget.value = value;
    this.sliderTarget.style.setProperty('--currentValue', `${value}%`);
  }

  setCurrentTime(time) {
    this.currentTimeTarget.innerText = new Date(Math.floor(time) * 1000).toISOString().slice(15, 19);
  }

}

/* Usage:
 * <button data-controller='copy-to-clipboard' data-action='click->copy-to-clipboard#copy' data-copy-to-clipboard-text-param='<%= @athlete.profile_url %>' data-copy-to-clipboard-message-param='Athlete profile URL copied'>
 */
import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';

export default class extends Controller {

  copy(event) {
    navigator.clipboard.writeText(event.params.text).then(() => {
      createAlert('success', event.params.message || "Copied!");
    }, (err) => {
      createAlert('danger', 'Error copying text to clipboard');
      console.log('Error: ', err);
    });

  }

}

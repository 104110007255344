import { Controller } from "@hotwired/stimulus"
import Shepherd from 'shepherd.js';

let tour = {};

export default class extends Controller {
   static values = {
    showTour: Boolean,
    tourStep: Number,
    fanwordLogo: String
  }

  connect() {
    // console.log("Got connected to Stories Tour Controller");
    // console.log('Show Tour:', this.showTourValue);
    // console.log('Tour Step:', this.tourStepValue);
    // console.log('Logo Value:', this.fanwordLogoValue);

    this.prepareTour();

    // init localStorage if needed
    let storiesTourSteps = localStorage.getItem('stories-tour');
    if (!storiesTourSteps) {
      this.resetTour();
      this.showTourStep(0);
    }

    // Listen for tabs to change and retrigger tour logic
    var tabs = document.querySelectorAll('button[data-bs-toggle="tab"]')
    tabs.forEach(elm => {
      elm.addEventListener('shown.bs.tab', (event) => {
        if (!this.alreadyDismissedStep(event.target.dataset.tourKey)) {
          this.showTourStep(event)
        }
      });
    });

    if (this.showTourValue === true) {
      const activeTab = document.querySelector('button.nav-link.active');
      const tourKey = activeTab.dataset.tourKey;
      if (!this.alreadyDismissedStep(tourKey)) {
        this.showTourStep(parseInt(tourKey))
      }
    }
  }

  async prepareTour() {

    tour = new Shepherd.Tour({
      useModalOverlay: true,
      exitOnEsc: true,
      keyboardNavigation: true,
      defaultStepOptions: {
        classes: 'fanword-shepherd-tour stories-tour',
        modalOverlayOpeningPadding: 10,
        scrollTo: false,
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [0, 20] } }]
        }
      }
    });

    tour.addSteps([
      // index 0 step 0 welcome modal
      {
        id: 'stories-tour-step-zero',
        text: `
          <div class='bg-fanword-blue d-flex flex-column justify-content-center align-items-center' style='min-height: 200px;'>
            <div class='bg-fanword-white my-5 d-flex flex-direction-column justify-content-center align-items-center' style='width: 100px; height: 100px; border-radius: 100px;'>
              <img src='${this.fanwordLogoValue}' style='width: 50%; display: block; margin: 0 auto;' />
            </div>
          </div>
          <div class='p-5'>
            <h2 class='mb-5 text-center'>Hi there!</h2>
            <p class='text-muted font-fanword-regular text-center lh-base'>
              Thank you for joining FanWord Stories. Simply click 'Get Started' to launch our quick product tour, showing you everything you need to know to master our storytelling software.
            </p>
            <div class='text-center'>
              <button class='btn-sm border-0 bg-fanword-yellow px-5 py-3 mb-3 color-fanword-black' type='button' id='tour-lets-go'>
                Get Started
              </button>
              <br>
              <a class='color-fanword-text font-fanword-regular text-decoration-none' href='#' id='stories-tour-skip'>No thanks, I'll explore on my own.</a>
            </div>
          </div>
        `,
        when: {
          show: () => {
            const skipButton = document.querySelector('#stories-tour-skip')
            skipButton.addEventListener('click', (event) => {
              event.preventDefault();
              tour.complete();
              this.completeStep(0);
              this.completeStep(1);
              this.completeStep(2);
              this.completeStep(3);
              this.completeStep(4);
              this.completeStep(5);
              this.completeStep(6);
              this.completeStep(7);
              this.completeStep(8);
            })

            const goButton = document.querySelector('#tour-lets-go')
            goButton.addEventListener('click', (event) => {
              this.completeStep(0);
              tour.next()
            })
          }
        }
      },

      // index 1 step 0 "Add New"
      {
        id: 'tour-step-one',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              When you're ready to get started, click on <span class="font-fanword-extra-bold">"Add New"</span> to create your first story.
            </p>
          </div>`,
        attachTo: {
          element: '.entity-admin-open-modal',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(1);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          },
        ]
      },

      // index 2 step 1 subject tooltip
      {
        id: 'stories-tour-step-two',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              In this first step, simply fill in the information about the athlete you want to do the feature story on. (You can edit this information until you send the interview to the athlete in Step 2)
            </p>
          </div>`,
        attachTo: {
          element: '#story-subject-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(2);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },

      // index 3 step 2 interview select
      {
        id: 'stories-tour-step-three-select',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              Next, please select your interview style. Use the "Help" button on the right if you need any additional information.
            </p>
          </div>`,
        attachTo: {
          element: '#story-interview-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(3);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },

      // index 4 step 2 interview online
      {
        id: 'stories-tour-step-three-online',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              Now, type in your interview questions. (Minimum of three questions)
            </p>
            <p>
              Click <span class="font-fanword-extra-bold">"Save"</span> when you're done.
            </p>
            <p class='font-fanword-regular'>
              Once you save your questions, you can send the interview to the athlete by clicking the <span class="font-fanword-extra-bold">"Send to Athlete"</span> button. The athlete will be notified via email and can open your interview on their laptops or phones.
            </p>
            <p class='font-fanword-regular'>
              When the athlete submits their responses, you'll get notified via email.
            </p>
            <p class='font-fanword-regular'>
              <span class="font-fanword-extra-bold">Important: </span> Please note that once you send your interview questions to the athlete, you can not change the athlete information from Step 1 anymore. Also, as soon as the athlete submits responses to the interview, you can not change the interview questions anymore either.
            </p>
          </div>`,
        attachTo: {
          element: '#story-interview-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(4);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },

      // index 5 step 2 interview audio
      {
        id: 'stories-tour-step-three-audio',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              Now, simply upload the audio file of an existing interview.
            </p>
          </div>`,
        attachTo: {
          element: '#story-interview-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(5);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },

      // index 6 step 2 responses online
      {
        id: 'stories-tour-step-three-online',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              If the athlete has not submitted their responses yet, you can send them a reminder via email.
            </p>
            <p class='font-fanword-regular'>
              If the athlete has submitted their responses, you see those responses in text form. We encourage you to read those responses, and edit, if necessary.
            </p>
            <p class='font-fanword-regular'>
              Click "<span class="font-fanword-extra-bold">Save</span>" when done.
            </p>
          </div>`,
        attachTo: {
          element: '#story-responses-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(6);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },

      // index 7 step 2 responses audio
      {
        id: 'stories-tour-step-three-audio',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              You can now review and edit the transcript
            </p>
            <p class='font-fanword-regular'>
              Click "<span class="font-fanword-extra-bold">Save</span>" when done.
            </p>
          </div>`,
        attachTo: {
          element: '#story-responses-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(7);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },

      // index 8 step 2 drafts
      {
        id: 'stories-tour-step-four',
        text: `<div class='p-4'>
            <p class='font-fanword-regular'>
              Now, it's time to write the story.
            </p>
            <p class='font-fanword-regular'>
              Click <span class="font-fanword-extra-bold">"New Draft"</span> to get started. (you can have unlimited drafts)
            </p>
            <p class='font-fanword-regular'>
              First, you are asked to select the <span class="font-fanword-extra-bold">"Voice"</span> for the story.
            </p>
            <p class='font-fanword-regular'>
              Then, and this is the most important part, tell the system what you want the story to be about. The more descriptive, the better.
            </p>
            <p class='font-fanword-regular'>
              Once you're happy with your input, click <span class="font-fanword-extra-bold">"Generate Draft"</span>.
            </p>
            <p class='font-fanword-regular'>
              Now, review and edit your draft, if necessary. You can also "Approve" or "Reject" a draft.
            </p>
            <p class='font-fanword-regular'>
              Please note that once you <span class="font-fanword-extra-bold">"Approve"</span> a draft, you can not create new drafts anymore.
            </p>
            <p class='font-fanword-regular'>
              Important: Use the help menu to see examples and learn about best practices.
            </p>
          </div>`,
        attachTo: {
          element: '#story-drafts-tab',
          on: 'bottom-start'
        },
        popperOptions: {
          modifiers: [{ name: 'offset', options: { offset: [60, 20] } }]
        },
        buttons: [
          {
            text: 'Got it',
            action: () => {
              this.completeStep(8);
              tour.hide();
            },
            classes: 'btn-sm bg-fanword-yellow border-0 ms-2 px-4 py-2 color-fanword-black text-decoration-none'
          }
        ]
      },
    ]);

  }

  completeStep(key) {
    let storage = JSON.parse(localStorage.getItem('stories-tour'));
    storage[key] = true;
    localStorage.setItem('stories-tour', JSON.stringify(storage))
  }

  alreadyDismissedStep(key) {
    let storage = JSON.parse(localStorage.getItem('stories-tour'));
    return storage[parseInt(key)]
  }

  showTourStep(tourKeyOrEvent) {
    // ensure we're at the top of the page before showing step
    document.querySelector('.entity-admin-content-page').scroll({ top: 0 });

    let tourKey;
    if (typeof tourKeyOrEvent === 'object') {
      tourKey = tourKeyOrEvent.target.dataset.tourKey;
    } else {
      tourKey = tourKeyOrEvent
    }

    let storage = JSON.parse(localStorage.getItem('stories-tour'));
    storage[tourKey] = false;
    localStorage.setItem('stories-tour', JSON.stringify(storage));

    tour.show(parseInt(tourKey));
  }

  resetTour(event) {
    let storiesTourSteps = {
      0: false, // welcome screen
      1: false, // add story
      2: false, // subject
      3: false, // interview - select
      4: false, // interview - online
      5: false, // interview - audio
      6: false, // responses - online
      7: false, // responses - audio
      8: false, // drafts
    }

    localStorage.setItem('stories-tour', JSON.stringify(storiesTourSteps));

    if (event && event.target) {
      tour.show(0);
    }
  }

}
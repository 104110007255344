import { Controller } from "@hotwired/stimulus"
import diff_match_patch from "diff-match-patch";

export default class extends Controller {
  static targets = [
    "submitButton", "textArea", "title", "wordCounter",
    "lengthContainer", "lengthInput", "generateButton",
    "synopsisModal" ]

  static values = {
    storyId: Number,
    baseUrl: String
  }

  connect() {
    // console.log("Hello, Stories Drafts Controller here!", this.element)

    // console.log(this.synopsisModalTarget);
    this.modal = this.hasSynopsisModalTarget && new bootstrap.Modal(this.synopsisModalTarget);

    this.hasWaitForTheMagicModalTarget && this.waitForTheMagicModalTarget.addEventListener('show.bs.modal', (e) => {
      this.waitForTheMagicModalTarget.querySelector('#modal-info').innerHTML = e.relatedTarget.dataset['message'];
    });

    this.updateSubmitDisabledState()
  }

  updateSubmitDisabledState(event) {
    if (!this.hasTextAreaTarget) {
      return;
    }

    let string;
    if (!event) {
      string = this.textAreaTarget.value;
    } else {
      string = event.target.value;
    }

    const wordCount = string.split(" ").filter(Boolean);
    this.wordCounterTarget.innerHTML = wordCount.length;

    if (this.hasSubmitButtonTarget) {
      if (wordCount.length >= 50) {
        this.submitButtonTarget.value && this.submitButtonTarget.toggleAttribute('disabled', false);
      } else {
        this.submitButtonTarget.value && this.submitButtonTarget.toggleAttribute('disabled', true);
      }
    }
  }

  determineLengthVisibility(event) {
    // console.log('Determing length visibility', event.target.value);
    const hasLength = event.target.selectedOptions[0].dataset['hasLength']
    if (hasLength === 'true') {
      this.lengthContainerTarget.classList.remove('d-none');
      this.lengthContainerTarget.classList.add('d-block');

      this.lengthInputTarget.disabled = false;
    } else {
      this.lengthContainerTarget.classList.remove('d-block');
      this.lengthContainerTarget.classList.add('d-none');

      this.lengthInputTarget.disabled = true
    }
  }

  generate(event) {
    if (event.target.dataset.confirmSwal) {
      // need to confirm first
      return;
    }

    this.generateButtonTarget.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Generating...';

    fetch(`/${this.baseUrlValue}/synopsis_help`).then(res => res.json()).then(data => {

      this.textAreaTarget.value = data.text;
      this.textAreaTarget.dispatchEvent(new Event("keyup"));
      this.textAreaTarget.focus();

      this.generateButtonTarget.dataset.confirmSwal = "Are you sure?"
      this.generateButtonTarget.dataset.text = "Re-generating a synopsis will remove the existing text and it cannot be recovered."
      this.generateButtonTarget.innerHTML = '<i class="fas fa-magic"></i> Generate Synopsis';
    });
  }

  openPreviousSynopsisModal(event) {
    // console.log('Opening previous synopsis modal');

    fetch(`/${this.baseUrlValue}/previous_synopsis`).then(response => response.text()).then(html => {

      this.synopsisModalTarget.querySelector('.modal-body').innerHTML = html;

      this.modal.show();
    });
  }

  usePreviousSynopsis(event) {
    // console.log('Using previous synopsis');
    const synopsis = event.params['synopsis'];
    if (synopsis) {
      this.textAreaTarget.value = synopsis;
      this.updateSubmitDisabledState();
      this.modal.hide();
    }
  }

}

import { Controller } from "@hotwired/stimulus"
import createAlert from '../packs/createAlert';
import ratio from '../packs/ratio';

let doc, activeUIBlock, assignContentModal, waitForItModal;

export default class extends Controller {
  static targets = [ "assignContentModal", "waitForItModal", "chunk", "textArea", "formElement", "form" ]
  static values = {
    url: String,
    username: String,
    password: String,
    story: Number,
    published: Boolean
  }

  connect() {
    // console.log("Hello, Stories Publish Controller here!", this.element);
    // console.log("Modal target", this.modalTarget);

    if (!this.publishedValue) {
      assignContentModal = new bootstrap.Modal(this.assignContentModalTarget);
      waitForItModal = new bootstrap.Modal(this.waitForItModalTarget);
    }
  }

  fetchTemplate(event) {
    const authHeader = btoa(`${this.usernameValue}:${this.passwordValue}`)
    fetch(event.target.value, {
      headers: {
        'Authorization': `Basic ${authHeader}`
      }
    }).then(resp => resp.json()).then(data => {

      // The Wordpress API's raw results will return back the Gutenburg HTML comments
      // and the DOMParser screws up in the first node is an HTML comment (puts it outside of the body)
      // so we wrap the Gutenburg content in a div so there's no cling-ons
      let html = `<div id='container'>${data.content.raw}</div>`

      doc = (new DOMParser).parseFromString(html, "text/html");

      // grab groups from this
      const groups = doc.querySelectorAll('.wp-block-group');

      this.buildInputsFromTemplate(groups);
    })
  }

  buildInputsFromTemplate(groups) {
    const accordion = document.querySelector('#publish-accordion');

    // reset the container
    accordion.innerHTML = "";

    groups.forEach((group, index) => {

      // create accordion group
      const item = document.createElement('div');
      item.classList.add('accordion-item');
      item.innerHTML = `
        <h2 class="accordion-header" id="group-header-${index}">
          <button class="accordion-button color-fanword-black collapsed py-4 px-2" type="button" data-bs-toggle="collapse" data-bs-target="#group-${index}" aria-expanded="true" aria-controls="group-${index}">
            <i id="indicator-${index}" class="fas fa-check-circle me-3 color-fanword-text"></i>
            Paragraph ${index + 1}
          </button>
        </h2>
        <div id="group-${index}" class="accordion-collapse collapse " aria-labelledby="group-header-${index}" data-bs-parent="#publish-accordion">
          <div class="accordion-body">
          </div>
        </div>
      `

      const itemBody = item.querySelector('.accordion-body')
      const elements = group.querySelectorAll('[id^="fanword"]');

      let elementToBuild, cachedSideBySideImage;

      elements.forEach(elm => {

        // curently we support side-by-side images in Wordpress templates with 1a, 1b
        // unfortunately, endsWith() will not accept a regex so we have to search for the specific
        // ending, which means we can't easily expand to N number of side-by-side images... 😶
        if (elm.id.endsWith('a')) {
          cachedSideBySideImage = elm;
          return;
        }

        if (cachedSideBySideImage) {
          elementToBuild = [cachedSideBySideImage, elm];
          cachedSideBySideImage = undefined;
        } else {
          elementToBuild = elm;
        }

        // build the form elements to keep track of the actual data
        const formMarkup = this.elementFactory(elementToBuild);

        itemBody.innerHTML = itemBody.innerHTML += formMarkup;
      }) // end elements loop

      accordion.appendChild(item);

    }) // end group loop

    // set up listeners for the completion indicators
    // console.log('targets', this.formElementTargets);
    this.formElementTargets.forEach(formElement => {
      formElement.addEventListener('change', this.checkForCompletion);
      formElement.addEventListener('input', this.checkForCompletion);
    });
  }

  elementFactory(elm) {
    if (Array.isArray(elm) || elm.id.includes('image')){

      if (Array.isArray(elm)) {
        const ratioArray1 = ratio(elm[0].dataset.fanwordWidth, elm[0].dataset.fanwordHeight);
        const ratioDecimal1 = ratioArray1[0]/ratioArray1[1];

        const ratioArray2 = ratio(elm[1].dataset.fanwordWidth, elm[1].dataset.fanwordHeight);
        const ratioDecimal2 = ratioArray2[0]/ratioArray2[1];
        return `
          <div class='mb-4'>
            <label class='form-label'>Photo</label>
            <div class="row">
              <div class="col-6" data-controller='crop' data-crop-width-value="${elm[0].dataset.fanwordWidth}" data-crop-height-value="${elm[0].dataset.fanwordHeight}" data-crop-aspect-ratio-value="${ratioDecimal1}" data-crop-file-name-value="side-by-side-a" data-action="crop:cropped->stories-publish#previewImage">
                <div id="preview_${elm[0].id}" class="text-center fanword-border color-fanword-text fanword-border-radius bg-fanword-background cursor-fanword-pointer d-flex flex-column justify-content-center position-relative" style="min-height:200px;background-size:cover;aspect-ratio:${ratioDecimal1}" data-action='click->stories-publish#triggerFileSelect' data-form-element-id="input_${elm[0].id}">
                  <div class='no-preview d-block pe-none'>
                    <p class="pe-none fs-1">+</p>
                    <p class="pe-none mb-2 font-fanword-bold">Click to upload photo</p>
                    <p class="pe-none m-0">1920x1280 recommended</p>
                  </div>
                  <div class='has-preview d-none position-absolute top-0 end-0 mt-2 me-2'>
                    <button type="button" data-action='click->stories-publish#removeImage:stop' data-element-id="${elm[0].id}" class='btn bg-fanword-white'>
                      <i class="fas fa-trash-alt color-fanword-black text-decoration-none pe-none"></i>
                    </button>
                  </div>
                </div>
                <input type='file' id='input_${elm[0].id}' data-id="${elm[0].id}" class='form-control p-3 d-none' data-stories-publish-target="formElement" data-crop-target='input' data-action='change->crop#changeHandler' />
              </div>

              <div class="col-6" data-controller='crop' data-crop-width-value="${elm[1].dataset.fanwordWidth}" data-crop-height-value="${elm[1].dataset.fanwordHeight}" data-crop-aspect-ratio-value="${ratioDecimal2}" data-crop-file-name-value="side-by-side-b" data-action="crop:cropped->stories-publish#previewImage">
                <div id="preview_${elm[1].id}" class="text-center fanword-border color-fanword-text fanword-border-radius bg-fanword-background cursor-fanword-pointer d-flex flex-column justify-content-center position-relative" style="min-height: 200px; background-size: cover; aspect-ratio:${ratioDecimal2}" data-action='click->stories-publish#triggerFileSelect' data-form-element-id="input_${elm[1].id}">
                  <div class='no-preview d-block pe-none'>
                    <p class="pe-none fs-1">+</p>
                    <p class="pe-none mb-2 font-fanword-bold">Click to upload photo</p>
                    <p class="pe-none m-0">1920x1280 recommended</p>
                  </div>
                  <div class='has-preview d-none position-absolute top-0 end-0 mt-2 me-2'>
                    <button type="button" data-action='click->stories-publish#removeImage:stop' data-element-id="${elm[1].id}" class='btn bg-fanword-white'>
                      <i class="fas fa-trash-alt color-fanword-black text-decoration-none pe-none"></i>
                    </button>
                  </div>
                </div>
                <input type='file' id='input_${elm[1].id}' data-id="${elm[1].id}" class='form-control p-3 d-none' data-stories-publish-target="formElement" data-crop-target='input' data-action='change->crop#changeHandler' />
              </div>
            </div>
          </div>
        `
      }

      const ratioArray = ratio(elm.dataset.fanwordWidth, elm.dataset.fanwordHeight);
      const ratioDecimal = ratioArray[0]/ratioArray[1];
      return `
        <div class='mb-4' data-controller='crop' data-crop-width-value="${elm.dataset.fanwordWidth}" data-crop-height-value="${elm.dataset.fanwordHeight}" data-crop-aspect-ratio-value="${ratioDecimal}" data-crop-file-name-value="fanword-image" data-action="crop:cropped->stories-publish#previewImage">
          <label class='form-label'>Photo</label>
          <div id="preview_${elm.id}" class="text-center fanword-border color-fanword-text fanword-border-radius bg-fanword-background cursor-fanword-pointer d-flex flex-column justify-content-center position-relative" style="min-height: 200px; background-size: cover; aspect-ratio:${ratioDecimal}" data-action='click->stories-publish#triggerFileSelect' data-form-element-id="input_${elm.id}">
            <div class='no-preview d-block pe-none'>
              <p class="pe-none fs-1">+</p>
              <p class="pe-none mb-2 font-fanword-bold">Click to upload photo</p>
              <p class="pe-none m-0">${elm.dataset.fanwordWidth}x${elm.dataset.fanwordHeight} recommended!</p>
            </div>
            <div class='has-preview d-none position-absolute top-0 end-0 mt-2 me-2'>
              <button type="button" data-action='click->stories-publish#removeImage:stop' data-element-id="${elm.id}" class='btn bg-fanword-white'>
                <i class="fas fa-trash-alt color-fanword-black text-decoration-none pe-none"></i>
              </button>
            </div>
          </div>
          <input type='file' id='input_${elm.id}' data-id="${elm.id}" class='form-control p-3 d-none' data-stories-publish-target="formElement" data-crop-target='input' data-action='change->crop#changeHandler' />
        </div>
      `
    }

    if (elm.id.includes('text')){
      return `
        <div class='mb-4'>
          <label class='form-label'>Paragraph</label>
          <textarea id='input_${elm.id}' rows="6" data-id="${elm.id}" readonly class='form-control p-3 color-fanword-text bg-fanword-background cursor-fanword-pointer' data-stories-publish-target="formElement textArea" data-action="click->stories-publish#presentModal" placeholder="Click to assign the paragraph"></textarea>
        </div>
      `
    }

    if (elm.id.includes('subtitle')){
      return `
        <div class='mb-4'>
          <label class='form-label'>Subtitle</label>
          <input type='text' id='input_${elm.id}' data-id="${elm.id}" class='form-control p-3' data-stories-publish-target="formElement" placeholder="Click to assign the subtitle" />
        </div>
      `
    }

    if (elm.id.includes('quote')){
      return `
        <div class='mb-4'>
          <label class='form-label'>Quote Text</label>
          <textarea id='input_${elm.id}-text' data-id="${elm.id}" data-quote-part="text" class='form-control p-3' data-stories-publish-target="formElement"></textarea>
        </div>
        <div class='mb-4'>
          <label class='form-label'>Quote Author</label>
          <input type='text' id='input_${elm.id}-author' data-id="${elm.id}" data-quote-part="author" class='form-control p-3' data-stories-publish-target="formElement" />
        </div>
      `
    }

    // shouldn't get here
  }

  presentModal(event) {
    activeUIBlock = event.target.id;
    assignContentModal.show();
  }

  triggerFileSelect(event) {
    event.stopPropagation();

    if (!event.target.dataset.formElementId) {
      return;
    }

    // console.log('publish_controller#triggerFileSelect', event.target.dataset.formElementId);

    document.querySelector(`#${event.target.dataset.formElementId}`).click();
  }

  previewImage(event) {
    if (event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        const container = document.querySelector(`#preview_${event.detail.content.dataset.id}`);

        // set the preview as the url
        container.style.backgroundImage = `url(${e.target.result})`;

        // remove the wording, enable the trash icon
        container.querySelector('.no-preview').classList.remove('d-block');
        container.querySelector('.no-preview').classList.add('d-none');
        container.querySelector('.has-preview').classList.remove('d-none');
        container.querySelector('.has-preview').classList.add('d-block');
      };

      reader.readAsDataURL(event.detail.content.files[0]);
    }
  }

  removeImage(event) {
    // console.log('Attempting to remove an image!', event);
    event.preventDefault();
    event.stopPropagation();

    const previewElement = document.querySelector(`#preview_${event.target.dataset.elementId}`);
    previewElement.style.backgroundImage = 'none';

    // remove the wording, enable the trash icon
    previewElement.querySelector('.has-preview').classList.remove('d-block');
    previewElement.querySelector('.has-preview').classList.add('d-none');
    previewElement.querySelector('.no-preview').classList.remove('d-none');
    previewElement.querySelector('.no-preview').classList.add('d-block');

    const formElement = document.querySelector(`#input_${event.target.dataset.elementId}`);
    formElement.value = null;

    var event = document.createEvent("UIEvents");
    event.initUIEvent("change", true, true);
    formElement.dispatchEvent(event);
  }

  toggleChunkActive(event) {
    // console.log('Togglging chunk', event);

    // if its an assigned chunk, we need to remove that class and de-assign it from the UI Block
    if (event.target.classList.contains('assigned-chunk')) {
      // remove all markings of previously assigned chunk
      [...event.target.classList].forEach(string => { string.startsWith('fanword') ? event.target.classList.remove(string) : '' })
      event.target.classList.remove('assigned-chunk');
      event.target.dataset['assignedTo'] = "";
    } else {
      event.target.classList.toggle('staged-chunk');
    }

  }

  assignContent(event) {
    // console.log('Assigning content!', event);

    const activeTextArea = document.querySelector(`#${activeUIBlock}`);

    // remove the value for ALL of textareas
    this.textAreaTargets.forEach(target => target.value = "");

    // assign all still assigned chunks back to textareas
    const stillAssignedChunks = this.chunkTargets.filter(elm => elm.classList.contains(`assigned-chunk`));
    stillAssignedChunks.forEach(chunk => {
      let formElement = document.querySelector(`#${chunk.dataset.assignedTo}`)
      formElement.value = formElement.value + "\n\n" + chunk.innerText + "\n\n";

      // trim excess linebreaks off the end of the string value
      formElement.value = formElement.value.trimEnd();
      formElement.value = formElement.value.trimStart();
    });

    // get the chunks that were selected
    const activeChunks = this.chunkTargets.filter(elm => elm.classList.contains('staged-chunk'))

    // update the UI Block to show what text is present AND update the form elements
    activeChunks.forEach(chunk => {
      // mark them so we know what UI block they belong to
      chunk.dataset['assignedTo'] = activeUIBlock;
      chunk.classList.add("assigned-chunk", activeUIBlock);
      chunk.classList.remove('staged-chunk');

      // let formElement = document.querySelector(`#${activeUIBlock}`);
      activeTextArea.value = activeTextArea.value + chunk.innerText + "\n\n";
    });

    // trim excess linebreaks off the end of the string value
    activeTextArea.value = activeTextArea.value.trimEnd();

    // textareas don't get updated when programmatically setting content, so trigger it
    // https://stackoverflow.com/a/60644856/1814019
    activeTextArea.dispatchEvent(new InputEvent('change'))

    assignContentModal.hide();
  }

  async publishStory(event) {
    event.preventDefault();

    waitForItModal.show();

    const controls = Array.from(event.srcElement)
      .filter(elm => !['submit', 'button'].includes(elm.type))
      .filter(elm => !['input_featuredMedia', 'publishTitle', 'publishSlug'].includes(elm.id)) // ignore the static fields

    for (const elm of controls) {

      if (!elm.value) {
        createAlert('danger', 'Not all sections have been filled out!')
        waitForItModal.hide();
        return;
      }

      const templateId = elm.id.split('_')[1];

      if (!templateId) {
        // the static form fields at the top will not need injecting into the template
        continue;
      }

      // differentiate how quotes and images are injected into the Gutenburg template
      if (templateId.includes('quote')) {
        // quotes in Wordpress are a single UI element, so we need to reduce it down to primary ID
        const parts = templateId.split('-');
        parts.pop();
        const wordpressTemplateId = parts.join('-')

        const figure = doc.querySelector(`#${wordpressTemplateId}`);
        if (elm.dataset.quotePart === 'text') {
          figure.querySelector('p').innerHTML = elm.value;
        } else if (elm.dataset.quotePart === 'author') {
          figure.querySelector('cite').innerHTML = elm.value;
        }

      } else if (templateId.includes('image')) {
        const figure = doc.getElementById(`${templateId}`);
        const image = figure.querySelector('img')

        // upload the file
        const wpImage = await this.uploadImage(elm);

        if (wpImage) {
          image.src = wpImage?.guid?.rendered;
        }

        // since the upload is an async process, we need to signal that the loop can continue
        continue;

      } else {
        doc.querySelector(`#${templateId}`).innerHTML = elm.value.replace('\n\n', '<br><br>');
      }

    };

    // deal with featured media
    const featuredMedia = await this.uploadImage(document.querySelector('#input_featuredMedia'));

    // console.log('publishing the post', doc.body.querySelector('#container').innerHTML)

    // should have the tempalte filled out, now need to submit it to Wordpress
    fetch(`${this.urlValue}/wp-json/wp/v2/posts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${btoa(`${this.usernameValue}:${this.passwordValue}`)}`
      },
      body: JSON.stringify({
        status: 'publish',
        title: document.querySelector('#publishTitle').value,
        author: 1, // TODO will need customized probably
        content: doc.body.querySelector('#container').innerHTML,
        slug: document.querySelector('#publishSlug').value,
        featured_media: featuredMedia.id
      })
    })
    .then(publishResults => publishResults.json())
    .then(async postData => {
      // debugger;

      await fetch(`/stories/${this.storyValue}/publish`, {
        method: 'POST',
        body: JSON.stringify({ url: postData.link })
      });

      createAlert('success', 'Wordpress page published!')

      // dismiss wait modal
      waitForItModal.hide();

      window.location.reload();
    }).catch( (error) => {
      // debugger;
      // console.log('Wordpress publishing error', error);
      createAlert('warning', 'Wordpress page not published')
    })

  }

  uploadImage(formElement) {
    const file = formElement.files[0]

    if (!file) {
      return false
    }

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("title", "fanword-publish-story");
    formData.append("caption", "this is caption");

    return fetch(`${this.urlValue}/wp-json/wp/v2/media`, {
      method: 'POST',
      headers: {
        'Content-Disposition': `attachment; filename="${file.name}"`,
        'Authorization': `Basic ${btoa(`${this.usernameValue}:${this.passwordValue}`)}`
      },
      body: formData,
      redirect: 'follow'
    })
    .then((response) => response.json())
    .then(result => {
      // console.log(result);
      return result;
    })
    .catch( (error) => {
      console.log(error);
    })
  }

  checkForCompletion(event) {
    event.preventDefault();

    // grab all of the groups
    const groups = document.querySelectorAll('#publish-accordion .accordion-item')

    // check all of the inputs in the group
    groups.forEach((group, index) => {
      const indicator = group.querySelector(`#indicator-${index}`);

      const controls = Array.from(group.querySelectorAll('input, textarea'));

      if(controls.every(control => control.value && control.value !== "")) {
        // if all have a value, set marker to green
        indicator.classList.remove('color-fanword-text');
        indicator.classList.add('color-fanword-green');
      } else {
        // if not, unset green class
        indicator.classList.remove('color-fanword-green');
        indicator.classList.add('color-fanword-text');
      }

    });


  }

}

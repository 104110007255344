import { Controller } from "@hotwired/stimulus"
import diff_match_patch from "diff-match-patch";

export default class extends Controller {
  static targets = [ 
    'reviewersContainer', 'reviewer', 'reviewerModal', 'reviewersTabs',
    'reviewerCheckbox', 'reviewerIds', 'sendNotificationButton', 'addReviewerSaveButton'
  ]

  connect() {

    this.reviewersTabsTarget.addEventListener('show.bs.tab', (e) => {
      this.displayDiff(e);
    });

    const initialTab = new bootstrap.Tab(this.reviewersTabsTarget.querySelector('button.active'));
    this.displayDiff(null, initialTab._element.dataset.bsTarget);
  }

  addReviewer(event) {
    event.preventDefault();

    const draft_id = event.params['id'];

    fetch(`/drafts/${draft_id}/reviewers/new`).then(res => res.text()).then(partial => {
      partial = partial.replace(/NEW_ID/g, this.reviewerTargets.length + 1)

      this.reviewersContainerTarget.insertAdjacentHTML( 'beforeend', partial );

      // add focus to newly created input
      this.reviewerTargets[this.reviewerTargets.length - 1].querySelector('input').focus();

      if (this.reviewerTargets.length > 0) {
        // console.log('> 0')
        this.addReviewerSaveButtonTarget.classList.remove("d-none");
        this.addReviewerSaveButtonTarget.classList.add("d-block");
      } else {
        // console.log('else')
        this.addReviewerSaveButtonTarget.classList.remove("d-block");
        this.addReviewerSaveButtonTarget.classList.add("d-none");
      }
    });
  }

  displayReviewDetails(event) {
    fetch(`/reviewers/${event.params.reviewerId}`).then(res => res.text()).then(partial => {
      this.reviewerModalTarget.innerHTML = "";
      this.reviewerModalTarget.insertAdjacentHTML( 'beforeend', partial );

      var dmp = new diff_match_patch();
      const draft = document.querySelector('#dmp-draft').value;
      const edit = document.querySelector('#dmp-edit').innerText;

      var d = dmp.diff_main(draft, edit);
      dmp.diff_cleanupSemantic(d);
      var ds = dmp.diff_prettyHtml(d);

      // the Google dmp library replaces the linebreaks \n with "&para;" in the text
      // I think we would prefer not to see those, so just take them out here.
      ds = ds.replace(/&para;/g, "");

      document.querySelector('#dmp-diff').innerHTML = ds;
    });
  }

  displayDiff(event, contentPane = null) {
    if (!contentPane) {
      contentPane = event.dataset ? event.dataset.bsTarget : event.target.dataset.bsTarget;
    }

    var dmp = new diff_match_patch();
    const originalDraft = document.querySelector('#original-draft').innerText.trim();
    const edit = document.querySelector(`${contentPane}-original`).innerText.trim();

    var d = dmp.diff_main(originalDraft, edit);
    dmp.diff_cleanupSemantic(d);
    var ds = dmp.diff_prettyHtml(d);

    // the Google dmp library replaces the linebreaks \n with "&para;" in the text
    // I think we would prefer not to see those, so just take them out here.
    ds = ds.replace(/&para;/g, "");

    document.querySelector(`${contentPane}-dmp`).innerHTML = ds;
  }

  toggleAllReviewers(event) {
    if (event.target.checked === false) {
      this.reviewerCheckboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.notificationRecipientsTarget.value = JSON.stringify([]);
      this.sendNotificationButtonTarget.setAttribute('disabled', true);
    } else {
      this.reviewerCheckboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = true;
      });
      this.notificationRecipientsTarget.value = JSON.stringify(this.reviewerCheckboxTargets.filter((checkbox) => !checkbox.disabled).map((checkbox) => parseInt(checkbox.value)));
      this.sendNotificationButtonTarget.removeAttribute('disabled');
    }
  }

  toggleReviewer({ params }) {
    let recipients = JSON.parse(this.reviewerIdsTarget.value);
    if (recipients.includes(params.reviewer)) {
      recipients = recipients.filter((recipient) => recipient !== params.reviewer);
    } else {
      recipients.push(params.reviewer);
    }
    this.reviewerIdsTarget.value = JSON.stringify(recipients);

    if (recipients.length > 0) {
      this.sendNotificationButtonTarget.removeAttribute('disabled');
    } else {
      this.sendNotificationButtonTarget.setAttribute('disabled', true);
    }
  }

}
